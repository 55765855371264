<template lang="pug">
.download-bank-slip
  h1 Boleto Bancário

  p Clique no botão abaixo para baixar o seu boleto bancário:

  .actions
    Button(
      target="_blank",
      :to="bankSlipUrl",
      label="Baixar Boleto Bancário",
      :internal="false"
    )

  p
    | Caso tenha algum problema para acessar o seu boleto bancário,&#32;
    | é só&#32;
    NuxtLink.contact(
      to="https://ajuda-magenta.zendesk.com/hc/pt-br/requests/new",
      target="_blank"
    ) falar com a gente!
</template>
<script setup lang="ts">
const route = useRoute()
const notifications = useNotifications()

const bankSlipUrl = ref<String | null>(null)

if (
  Array.isArray(route.query.url) &&
  route.query.url.length > 0 &&
  typeof route.query.url === "string"
) {
  bankSlipUrl.value = decodeURI(route.query.url[0]);
} else if (typeof route.query.url === "string") {
  bankSlipUrl.value = decodeURI(route.query.url);
} else {
  navigateTo({ name: "home" }, { replace: true })

  notifications.error("Não foi possível encontrar o boleto bancário.")
}
</script>
<style lang="sass" scoped>
@import '~/assets/styles/variables'

.download-bank-slip
  position: relative
  max-width: 708px
  margin: 0 auto
  padding: 0 14px
  text-align: left
  animation: fade-in .2s forwards

  h1
    margin-bottom: 0

  .actions
    margin: 64px 0
    text-align: center

  a.contact
    text-decoration: underline
    color: $magenta
</style>
